import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <h1>Fant ikke siden</h1>
    <p>Du kom til en side som ikke finnes.</p>
  </Layout>
)

export default NotFoundPage
